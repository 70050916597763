import Button, { ButtonVariants } from '@/components/atoms/legacy/Button';
import LocationAutoCompleteInput from '@/components/molecules/LocationAutoCompleteInput/LocationAutoCompleteInput';
import PopOutModal from '@/components/molecules/legacy/PopOutModal/PopOutModal';
import { useZipContext } from '@/context/ZipContext';
import { useGooglePlacesLibrary } from '@/hooks/useGooglePlacesLibrary';
import { useSearchQuery } from '@/hooks/useQuery';
import { browserLocation } from '@/lib/browserLocation';
import { DEFAULT_US_ZIP_CODE, GOOGLE_API_KEY } from '@/lib/constants';
import GoogleMapReact from 'google-map-react';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

const DefaultMapSettings = {
  center: {
    lat: 40.7128,
    lng: -74.006,
  },
  zoom: 12,
};
const validateZipCode = (zipCode?: string) => {
  return zipCode && zipCode.length === 5 && /^\d+$/.test(zipCode);
};

export interface LocationPopOutModalProps {
  open: boolean;
  initialZipCode?: string | null;
  callBackFunction?: (postalCode: string) => void;
  close: () => void;
  shouldStayOrigin?: boolean;
  setCustomAddress?: (address: string) => void;
}

export const LocationPopOutModal: FC<LocationPopOutModalProps> = ({
  open,
  initialZipCode = null,
  callBackFunction,
  close,
  shouldStayOrigin = false,
  setCustomAddress = () => {},
}) => {
  const router = useRouter();
  const {
    data: { location },
  } = useSearchQuery<{ location: string }>();

  const value = initialZipCode || location || DEFAULT_US_ZIP_CODE;
  const isGooglePlacesLibraryLoaded = useGooglePlacesLibrary();

  const [zipCode, setZipCode] = useState<string | undefined>(value);
  const [googleMap, setMap] = useState<google.maps.Map>();
  const [message, setMessage] = useState('');
  const debounceZipCode = useDebounce(zipCode, 500);
  const { setCustomZip } = useZipContext();

  const [verifiedZipCode, setVerifiedZipCode] = useState<boolean>(false);

  const [loadingGeoLocation, setLoadingGeoLocation] = useState<boolean>(false);

  useEffect(() => {
    setZipCode(value);
  }, [open, value]);

  useEffect(() => {
    // validate zip code
    if (validateZipCode(zipCode)) {
      setMessage('');
    } else {
      setVerifiedZipCode(false);
      if (zipCode !== undefined) setMessage('Please enter a valid zip code');
    }
  }, [verifiedZipCode, zipCode]);

  useEffect(() => {
    if (googleMap && debounceZipCode && validateZipCode(debounceZipCode)) {
      fetch(
        `/api/geo/geocode?${new URLSearchParams({ postalCode: debounceZipCode })}`
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error();
        })
        .then((data) => {
          const pos = new google.maps.LatLng(
            data.coordinates[0],
            data.coordinates[1]
          );
          googleMap?.setCenter(pos);

          if (data.country !== 'US') {
            setVerifiedZipCode(false);
            setMessage(
              'The zipcode you entered does not belong in the United States.'
            );
          } else {
            setVerifiedZipCode(true);
          }
        })
        .catch(() => {
          setMessage(
            'We could not find the location you entered. Please check the input and try again.'
          );
        });
    }
  }, [debounceZipCode, googleMap, verifiedZipCode]);

  const useGeolocation = () => {
    setVerifiedZipCode(false);
    setLoadingGeoLocation(true);
    browserLocation()
      .then(({ latLng, zip }) => {
        googleMap?.setCenter(latLng);
        setVerifiedZipCode(true);
        setLoadingGeoLocation(false);
        setZipCode(zip || '');
        setMessage('');
      })
      .catch(() => {
        setLoadingGeoLocation(false);
        setMessage('Could not retrieve US zipcode from browser.');
      });
  };

  const updateLocationInPath = (newZipCode: string) => {
    // replace the zip code in the url
    if (router.pathname.includes('[location]'))
      router.replace({
        pathname: router.pathname,
        query: {
          ...router.query,
          location: newZipCode,
        },
      });
    else
      router.replace(
        {
          pathname: '/build/[location]',
          query: {
            ...router.query,
            location: newZipCode,
          },
        },
        undefined,
        { shallow: false }
      );
  };

  const handleSubmit = (postalCode: string) => {
    callBackFunction?.(postalCode);
    setCustomZip(postalCode);
    close();
    if (!shouldStayOrigin) {
      updateLocationInPath(postalCode);
    }
  };

  const handleChangeZipCode = (char: string) => {
    setZipCode(char.toUpperCase());
  };

  useEffect(() => {
    if (loadingGeoLocation) document.body.classList.add('!cursor-progress');
    else document.body.classList.remove('!cursor-progress');
  }, [loadingGeoLocation]);

  return (
    <PopOutModal
      open={open}
      onClose={close}
      titleClassName="!px-0 pb-l"
      className="max-h-[90vh] w-screen max-w-[90vw] px-xl pb-xl pt-0 md:px-3xl md:pb-3xl md:pt-xs"
    >
      <div className={'flex flex-col gap-l md:gap-3xl'}>
        <div className={'flex flex-col items-start gap-s'}>
          <div className="text-h2SemiBold">Search by location</div>
          <div className="text-body1Regular text-neutralsGrey-600 md:text-h4Regular md:text-neutralsGrey-700">
            Customize your location for vehicle availability and EV incentives.
          </div>
        </div>
        <div className="flex flex-col s:gap-xl m:flex-row m:gap-2xl">
          <div
            className={
              'order-2 flex w-full flex-col items-stretch justify-between gap-xl l:w-1/2'
            }
          >
            <div className="order-1">
              <LocationAutoCompleteInput
                zipCode={zipCode}
                handleChangeZipCode={handleChangeZipCode}
                useGeolocation={useGeolocation}
                message={message}
                setCustomAddress={setCustomAddress}
                setZipCode={setZipCode}
                isGooglePlacesLibraryLoaded={isGooglePlacesLibraryLoaded}
                invalid={message.length > 0}
              />
            </div>

            <div className="relative order-2 flex h-full min-h-[245px] w-full overflow-hidden rounded-xsmall md:hidden">
              <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                yesIWantToUseGoogleMapApiInternals={true}
                defaultCenter={DefaultMapSettings.center}
                defaultZoom={DefaultMapSettings.zoom}
                options={{
                  disableDefaultUI: true,
                }}
                onGoogleApiLoaded={({ map }) => {
                  setMap(map);
                }}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              ></GoogleMapReact>
            </div>

            <div className="stretch-items order-3 flex flex-col">
              <Button
                variant={ButtonVariants.Secondary}
                onClick={() => {
                  if (zipCode) {
                    handleSubmit(zipCode);
                  }
                }}
                disabled={
                  !validateZipCode(zipCode) ||
                  message.length > 0 ||
                  !zipCode ||
                  !verifiedZipCode ||
                  loadingGeoLocation
                }
              >
                Update location
              </Button>
            </div>
          </div>

          <div
            className={
              'relative hidden h-full min-h-[200px] w-full gap-5 overflow-hidden rounded-xsmall md:order-2 md:flex md:min-h-[300px] l:w-7/12 l:min-w-[425px]'
            }
          >
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
              yesIWantToUseGoogleMapApiInternals={true}
              defaultCenter={DefaultMapSettings.center}
              defaultZoom={DefaultMapSettings.zoom}
              options={{
                disableDefaultUI: true,
              }}
              onGoogleApiLoaded={({ map }) => {
                setMap(map);
              }}
              style={{
                width: '100%',
                height: '100%',
              }}
            ></GoogleMapReact>
          </div>
        </div>
      </div>
    </PopOutModal>
  );
};
