import FormInput from '@/components/molecules/FormInput';
import { DEFAULT_US_ZIP_CODE } from '@/lib/constants';
import { cities } from '@/lib/seo/us-locations/cities';
import { states } from '@/lib/seo/us-locations/states';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';

interface LocationAutoCompleteInputProps {
  zipCode?: string;
  handleChangeZipCode: (arg1: string) => void;
  message: string;
  useGeolocation: () => void;
  setCustomAddress?: (arg1: string) => void;
  setZipCode: (arg1: string) => void;
  isGooglePlacesLibraryLoaded: boolean;
  label?: string;
  onBlur?: (postalCode: string) => void;
  invalid: boolean;
}

const LocationAutoCompleteInput: React.FC<LocationAutoCompleteInputProps> = ({
  zipCode,
  handleChangeZipCode,
  message,
  useGeolocation,
  setCustomAddress = () => {},
  setZipCode,
  isGooglePlacesLibraryLoaded,
  label = 'Location',
  onBlur = () => {},
  invalid,
}) => {
  useEffect(() => {
    if (!isGooglePlacesLibraryLoaded) return;

    const address1Field = document.getElementById(
      'address-input'
    ) as HTMLInputElement;

    if (address1Field) {
      const autocomplete = new google.maps.places.Autocomplete(address1Field, {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_components'],
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let postcode = '';
        let address = '';
        let city = '';
        let state = '';
        if (place.address_components) {
          place.address_components.forEach((component) => {
            const componentType = component.types[0];
            switch (componentType) {
              case 'postal_code':
                postcode = component.short_name;
                break;
              case 'sublocality_level_1':
                address = component.short_name;
                break;
              case 'neighborhood':
              case 'locality':
              case 'political':
              case 'natural_feature':
                city = component.long_name;
                if (!address) {
                  address = component.short_name;
                }
                break;
              case 'administrative_area_level_1':
                state = component.short_name;
                address += `, ${component.short_name}`;
                break;
              case 'country':
                address += `, ${component.short_name}`;
                break;
              default:
                break;
            }
          });

          if (!postcode) {
            const citySearchString = `${city.toLowerCase().split(' ').join('-')}-${state.toLowerCase()}`;
            const stateSearchString = state.toLowerCase();
            if (cities[citySearchString]) {
              postcode = cities[citySearchString].postalCode;
            } else if (states[stateSearchString]) {
              postcode = states[stateSearchString].postalCode;
            } else {
              postcode = DEFAULT_US_ZIP_CODE;
            }
          }
          setZipCode(postcode);
          setCustomAddress(address);

          if (onBlur) {
            onBlur(postcode);
          }
        }
      });
    }
  }, [
    setCustomAddress,
    isGooglePlacesLibraryLoaded,
    zipCode,
    setZipCode,
    onBlur,
  ]);
  return (
    <FormInput
      id="address-input"
      value={zipCode || ''}
      onChange={handleChangeZipCode}
      placeholder={'City or Zip Code'}
      label={label}
      errorMessage={message}
      invalid={invalid}
      icon={<MapPinIcon className="w-[20px] cursor-pointer" />}
      handleIconClick={useGeolocation}
      onBlur={() => onBlur(zipCode || '')}
    />
  );
};

export default LocationAutoCompleteInput;
